const flashMessage = document.querySelector('#flash_message');

const fadeOutFlashMessage = () => {
  const timerId = setInterval(() => {
    const { opacity } = flashMessage.style;
    if (opacity > 0) {
      flashMessage.style.opacity = opacity - 0.07;
    } else {
      flashMessage.style.display = 'none';
      clearInterval(timerId);
    }
  }, 50);
};

if (flashMessage !== null) {
  flashMessage.style.opacity = 1;
  setTimeout(fadeOutFlashMessage, 4000);
}
